<script>
    import {onMount} from "svelte";

    // on navigating to this page, scroll to the top
    onMount(() => window.scrollTo(0,0));
</script>

<div class="container mx-auto max-w-4xl p-4">
    <h1 class="text-5xl my-12 font-bold">About: Freed By Knowledge</h1>
    <p class="my-4">
        Freed By Knowledge is a place dedicated to reflecting truth and its associated laws and principles. We
        hope to succinctly share critical knowledge in core concepts that can easily be understood and
        applied. We strive to communicate processes through which we can “receive” and then “reap” the
        fruit of knowledge that will assist our progression.
    </p>
    <p class="my-4">
        We acknowledge that God loves ALL of His children and invites all to come to Him and partake of His
        truth and light. We know that God’s Holy Spirit will enlighten and direct all who invite its influence.
    </p>
    <p class="my-4">
        We seek to know what God has revealed and what God is willing to “reveal”. We join with the many
        individuals and communities throughout the world striving to reflect God’s light into dark places, to
        help bring about and establish His kingdom and glory.
    </p>
    <h3 class="my-12 text-2xl font-bold">Freed:</h3>
    <p class="my-4">
        1828 Definition:
    </p>
    <p class="mx-10 my-4 italic">
        Set at liberty; loosed; delivered from restraint; cleared of hindrance or obstruction.
        Unconstrained; unrestrained; not under compulsion or control. A man is free to pursue
        his own choice. He enjoys free will.
    </p>
    <p class="my-4">
        To truly be free does not mean to not have any accountability or responsibilities. On the contrary, to
        be free means to be enabled with greater abilities and powers because of our commitments to laws
        and principles rooted in truth.
    </p>
    <h3 class="my-12 text-2xl font-bold">By:</h3>
    <p class="my-4">
        This word is key and emphasizes and secures that it is Knowledge that we are Freed “by”. Freedom
        comes at a price. Freedom is not free. This is an invitation to be ”freed by knowledge” so that we are
        not “bound by ignorance” and deceptions that masquerade as freedom.
    </p>
    <h3 class="my-12 text-2xl font-bold">Knowledge:</h3>
    <p class="my-4">
        1828 Definition:
    </p>
    <p class="mx-10 my-4 italic">
        A clear and certain perception of that which exists, or of truth and fact. Learning;
        illumination of mind. Ignorance is the curse of God, knowledge is the wing wherewith
        we fly to heaven.
    </p>
    <p class="my-4">
        Truth is knowledge of things as they are, were, and are to come (DC 93:24). Whatsoever is more or
        less than this is the spirit of that wicked one who was a liar from the beginning.
    </p>
    <p class="my-4">
        Knowledge has the power to liberate us from bondage if we will seek it, receive it and reap its fruits.
        There are seemingly endless examples throughout history and in the world today of individuals and
        communities that were and are in bondage because of lack of knowledge or lack of will to understand
        and apply great knowledge. In a world of darkness and bondage, we are all invited to humble
        ourselves and become freed by knowledge.
    </p>
</div>