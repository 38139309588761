<script>
    import Display from '../components/package/Display.svelte';
    import {getPackage, packageData} from "../stores/package";
    import Spinner from '../components/Spinner.svelte';

    export let video = '';

    getPackage(video);
</script>
{#if $packageData.package.loading}
    <div class="text-4xl w-full text-center mt-3">Loading... <Spinner /></div>
{:else if $packageData.package.error}
    <div class="text-2xl text-red-600 w-full text-center mt-3">There was an error loading the data. Please try again later.</div>
{:else}
    <Display />
{/if}