<script>
    import page from 'page';
    import Header from '../components/Header.svelte';
    import Footer from '../components/Footer.svelte';

    import Home from './Home.svelte';
    import Packets from './Packets.svelte';
    import NotFound from './NotFound.svelte';
    import PackageDisplay from './ViewPacket.svelte';
    import About from './About.svelte';
    import ContactThanks from './ContactThanks.svelte';

    import Admin from './admin/Admin.svelte';
    import Users from './admin/Users.svelte';
    import Categories from './admin/Categories.svelte';

    let current = Home;
    let menuItem = Home;
    let params = {};

    page('/', () => {current = Home; menuItem = "home"});
    page('/about', () => {current = About; menuItem = "about"});
    page('/thanks', () => {current = ContactThanks; menuItem = "contact-thanks"});
    page('/packets', () => {current = Packets; menuItem = "packets"});
    page('/packets/:video', (ctx, next) => {
        params = ctx.params;
        current = PackageDisplay;
        menuItem = "packets";
    } );

    page('/admin', () => {current = Admin; menuItem = "admin"} )
    page('/admin/users', () => {current = Users; menuItem = "users"} )
    page('/admin/categories', () => {current = Categories; menuItem = "categories"} )

    page('*', () => {current = NotFound; menuItem = undefined});
    page.start();
</script>
<Header menuItem={menuItem} />
<div class="footer-bg text-gray-500 min-h-screen pt-16">
<svelte:component this={current} {...params}/>
    <Footer />
</div>