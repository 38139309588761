<script>
    import {isAdmin} from "../../stores/user";
    import {onMount} from 'svelte';
    import {category, getCategories} from "../../stores/category";
    import Spinner from '../../components/Spinner.svelte';
    import Modal from '../../components/Modal.svelte';
    import Edit from '../../components/category/Edit.svelte';

    let showEdit = false;
    let editCategory = {};
    onMount(() => {
        if (isAdmin()) {
            getCategories()
        }
    })
    function handleEditCategory(cat = false) {
        console.log(cat);
        if (cat) {
            editCategory = cat;
        } else {
            editCategory = {};
        }
        showEdit = true;
    }
</script>


{#if isAdmin()}
    <div class="container mx-auto pt-6">
        <ul class="list-none text-center text-2xl pt-3">
            <li class="shadow hover:bg-gray-200 px-5 py-2 mx-3 inline-block rounded-3xl bg-white"><a href="/admin">Packages</a>
            </li>
            <li class="shadow hover:bg-gray-200 px-5 py-2 mx-3 inline-block rounded-3xl bg-white"><a
                    href="/admin/categories">Categories</a></li>
            <li class="shadow hover:bg-gray-200 px-5 py-2 mx-3 inline-block rounded-3xl bg-white"><a
                    href="/admin/users">Users</a></li>
        </ul>

        {#if $category.categories.loading}
            <Spinner class="mt-4 text-center text-2xl"/>
        {/if}
        {#if $category.categories.error}
            <p class="text-red-500 mt-4 text-2xl text-center">{$category.categories.error}</p>
        {/if}
        <div class="text-right m-4">
            <button class="button button-green px-5 py-2 shadow hover:bg-gray-200 rounded-3xl" on:click={() => handleEditCategory()}>Add <i
                    class="icon-plus"></i></button>
        </div>
        <div class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Name
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Position
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            {#if !$category.categories.data || $category.categories.data.length === 0 }
                                <tr>
                                    <td colspan="5"><p class="text-2xl m-4 text-center">No categories to display.</p></td>
                                </tr>
                            {:else}
                                {#each $category.categories.data as cat}
                                    <tr>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{cat.Name}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{cat.Position}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button
                                                    class="text-indigo-600 hover:text-indigo-900"
                                                    on:click={() => handleEditCategory(cat)}
                                            >Edit</button>
                                        </td>
                                    </tr>
                                {/each}
                            {/if}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal bind:show="{showEdit}">
        <Edit data="{editCategory}" />
    </Modal>

{:else }
    <p class="text-center text-red-500 text-4xl m-6">Access Forbidden</p>
{/if}