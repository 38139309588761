<script>
    import {user, login, logout, isAdmin} from '../stores/user';
    import Spinner from './Spinner.svelte';

    let showForm = false;
    let username = '';
    let password = '';
    let classes = 'px-2 py-1 button hover:shadow rounded-3xl hover:bg-gray-200 text-2xl text-gray-500';

    if ($user.authenticated) {
        classes += ' text-green-500';
    }

    function handleLogin() {
        return login(username, password);
    }
    function handleLogout() {
        return logout();
    }
</script>


<button class={classes} on:click={() => showForm = true}><i class="icon-user"></i></button>

{#if showForm}
    {#if $user.authenticated}
        <div class="absolute rounded-md shadow-md bg-white p-4 bottom-0 text-base max-w-xs z-20 min-w-max">
            <button class="px-3 py-1 float-right shadow rounded-3xl hover:bg-red-500 -mt-2" on:click={() => showForm = false} aria-label="Close Login Form">
                <i class="icon-cross"></i>
            </button>
            <p class="block my-6">Welcome, {$user.username}</p>
            {#if isAdmin()}
                <a class="my-2 px-2 py-1 button shadow rounded-3xl hover:bg-gray-200" href="/admin">Administration</a>
            {/if}
            {#if !$user.loading}
                <button class="px-2 py-1 block float-right button m-3 shadow rounded-3xl hover:bg-gray-200" on:click={handleLogout}>Log Out</button>
            {:else }
                <div class="float-right m-3"><i class="spinner icon-spinner5"></i></div>
            {/if}
        </div>
    {:else }
        <div class="absolute rounded-md shadow-md bg-white p-4 -top-44 -left-28 md:-top-2 md:-right-2 md:left-auto text-base max-w-xs z-20">
            <button class="px-3 py-1 float-right shadow rounded-3xl hover:bg-red-500 -mt-2" on:click={() => showForm = false} aria-label="Close Login Form">
                <i class="icon-cross"></i>
            </button>
            <label class="block">Username:
                <input class="p-2 my-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" name="username" bind:value={username} type="text" placeholder="MyName">
            </label>
            <label class="block">Password:
                <input class="p-2 my-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" name="password" bind:value={password} placeholder="Secret!" type="password">
            </label>
            {#if $user.error}
                <div class="text-red-500">{$user.error}</div>
            {/if}
            {#if !$user.loading}
                <button class="px-2 py-1 block float-right button m-3 shadow rounded-3xl hover:bg-gray-200" on:click={handleLogin}>Log In</button>
            {:else }
                <div class="float-right m-3"><Spinner /></div>
            {/if}
        </div>
    {/if}
{/if}