<script>
    import {isAdmin} from "../../stores/user";
    import {onMount} from 'svelte';
    import {packageData, getPackages, getPackage, deletePackage} from "../../stores/package";
    import Display from '../../components/package/Display.svelte';
    import Spinner from '../../components/Spinner.svelte';
    import Modal from '../../components/Modal.svelte';
    import Edit from '../../components/package/Edit.svelte';

    let showEdit = false;
    let showView = false;
    let editPackage = {};
    onMount(() => {
        if (isAdmin()) {
            getPackages()
        }
    })
    function handleEditPackage(pkg = false) {
        console.log(pkg);
        if (pkg) {
            editPackage = pkg;
        } else {
            editPackage = {};
        }
        showEdit = true;
    }
    function handleDeletePackage(pkg) {
        if (confirm("Are you sure you want to delete package " + pkg.Name + "?")) {
            console.log(pkg);
            deletePackage(pkg.ID).then((response) => {
                if (response === true) {
                    getPackages()
                } else {
                    alert("Error deleting package.");
                }
            })
            .catch((error) => {
                console.log(error);
                alert("Error deleting package.");
            })
        }
    }
</script>


{#if isAdmin()}
    <div class="container mx-auto pt-6">
        <ul class="list-none text-center text-2xl pt-3">
            <li class="shadow hover:bg-gray-200 px-5 py-2 mx-3 inline-block rounded-3xl bg-white"><a href="/admin">Packages</a>
            </li>
            <li class="shadow hover:bg-gray-200 px-5 py-2 mx-3 inline-block rounded-3xl bg-white"><a
                    href="/admin/categories">Categories</a></li>
            <li class="shadow hover:bg-gray-200 px-5 py-2 mx-3 inline-block rounded-3xl bg-white"><a
                    href="/admin/users">Users</a></li>
        </ul>

        {#if $packageData.packages.loading}
            <Spinner class="mt-4 text-center text-2xl"/>
        {/if}
        {#if $packageData.packages.error}
            <p class="text-red-500 mt-4 text-2xl text-center">{$packageData.packages.error}</p>
        {/if}
        <div class="text-right m-4">
            <button class="button button-green px-5 py-2 shadow hover:bg-gray-200 rounded-3xl" on:click={() => handleEditPackage()}>Add <i
                    class="icon-plus"></i></button>
        </div>
        <div class="flex flex-col mt-4">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Title
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Video
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Category
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Position
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Status
                                </th>
                                <th scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Actions</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                            {#if !$packageData.packages.data || $packageData.packages.data.length === 0}
                                <tr>
                                    <td colspan="5"><p class="text-2xl m-4 text-center">No packages to display.</p></td>
                                </tr>
                            {:else}
                                {#each $packageData.packages.data as pkg}
                                    <tr>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="flex items-center">
                                                <div class="flex-shrink-0 h-10 w-16">
                                                    <img class="h-10 w-16"
                                                         src="{pkg.Thumb}"
                                                         alt="Thumb">
                                                </div>
                                                <div class="ml-4">
                                                    <div class="text-sm font-medium text-gray-900">
                                                        {pkg.Name}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{pkg.Video}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{pkg.Category && pkg.Category.ID ? pkg.Category.Name : 'None'}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            <div class="text-sm text-gray-900">{pkg.Position}</div>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {#if pkg.Published}
                                                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                  Published
                                                </span>
                                            {:else}
                                                <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                  Unpublished
                                                </span>
                                            {/if}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button
                                                    class="text-indigo-600 hover:text-indigo-900"
                                                    on:click={() => {getPackage(pkg.Video); showView = true}}
                                            ><i class="icon-eye" title="View"></i></button>
                                            <button
                                                    class="text-indigo-600 hover:text-indigo-900"
                                                    on:click={() => handleEditPackage(pkg)}
                                            ><i class="icon-pencil" title="Edit"></i></button>
                                            <button
                                                    class="text-indigo-600 hover:text-indigo-900"
                                                    on:click={() => handleDeletePackage(pkg)}
                                            ><i class="icon-bin" title="Delete"></i></button>
                                        </td>
                                    </tr>
                                {/each}
                            {/if}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal bind:show="{showEdit}">
        <Edit data="{editPackage}" />
    </Modal>
    <Modal bind:show="{showView}">
        <Display />
    </Modal>

{:else }
    <p class="text-center text-red-500 text-4xl m-6">Access Forbidden</p>
{/if}