<script>
    import {Howl} from 'howler';
    export let data = {};
    console.log(data);
    let howl = new Howl({
        src: [data ? data.Audio[0] === '.' ? data.Audio.slice(1) : data.Audio: '']
    });
    let download = data ? data.Download[0] === '.' ? data.Download.slice(1) : data.Download : '';

    function audio() {
        if (howl.playing()) {
            howl.pause();
        } else {
            howl.play();
        }
    }
</script>

<div class="absolute -bottom-12 right-4">
    {#if data && data.Audio}
        <button title="Listen to audio"
                class="px-2 py-1 button rounded-3xl shadow-md text-2xl font-extrabold outline-none focus:outline-none hover:bg-gray-300 bg-white"
                on:click={()=>audio()}>
            <i class="icon-headphones"></i>
        </button>
    {/if}
    {#if download}
        <a href={download} download>
            <button title="Download PDF"
                    class="text-gray-500 px-2 py-1 button rounded-3xl shadow-md text-2xl font-extrabold outline-none focus:outline-none hover:bg-gray-300 bg-white">
                <i class="icon-download3"></i>
            </button>
        </a>
    {/if}
    {#if download}
        <a href={download} target="_blank">
            <button title="Print PDF"
                    class="text-gray-500 px-2 py-1 button rounded-3xl shadow-md text-2xl font-extrabold outline-none focus:outline-none hover:bg-gray-300 bg-white">
                <i class="icon-printer"></i>
            </button>
        </a>
    {/if}
</div>
