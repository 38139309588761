<script>
    import {onMount} from 'svelte';

    let saving = false;
    let error = false;
    export let data = {
        ID: '',
        Name: '',
        Position: '',
    };

    function handleSave() {
        return () => {
            saving = true;
            console.log('saving package');
            let body = JSON.stringify(data);
            let headers = new Headers({
                "Content-Type": "application/json",
                "Content-Length": body.length.toString()
            })

            let method = 'POST';
            let url = '/api/v1/categories';
            if (data.ID) {
                method = 'PUT'
                url += '/' + data.ID;
            }

            fetch(url, {
                method: method,
                headers: headers,
                body: body
            })
                .then((msg) => {
                    if (msg.ok) {
                        console.log('successfully saved')
                        if (msg.headers.get('location')) {
                            saving = false;
                            window.location = msg.headers.get('location');
                        }
                    } else {
                        // not success
                        console.log(msg.statusText);
                        error = true;
                        saving = false;
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    error = true;
                    saving = false;
                })
        }
    }

    onMount(() => {

    });
</script>

<div class="container mx-auto">
    <div class="lg:mx-4">
        <div class="mt-5 md:mt-0">
            <form action="#" method="POST" id="package-edit">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div class="grid grid-cols-3 gap-6">
                            <div class="col-span-3 sm:col-span-2">
                                <label for="name" class="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <div class="mt-1 flex rounded-md shadow-sm">
                                    <input type="text" name="name" id="name"
                                           bind:value={data.Name}
                                           class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                           placeholder="Category Name">
                                </div>
                            </div>
                        </div>

                        <div>
                            <label for="position" class="block text-sm font-medium text-gray-700">
                                Category Position
                            </label>
                            <div class="mt-1">
                                <input type="number" name="position" id="position"
                                       bind:value={data.Position}
                                       class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                       placeholder="5">
                            </div>
                            <p class="mt-2 text-sm text-gray-500">
                                 The position of the category in the display page. Lower numbers display before higher numbers.
                            </p>
                        </div>
                    </div>
                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        {#if error}
                            <p class="text-red-500 mt-4 text-2xl text-center">Error saving data, please check your inputs!</p>
                        {/if}
                        <button type="submit"
                                disabled={saving}
                                on:click|preventDefault={handleSave()}
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<style global>

</style>