<script>
    import {packageData} from '../../stores/package';
    import Buttons from './Buttons.svelte';
    import Marked from 'marked';
    import Spinner from '../Spinner.svelte';
</script>

<div class="container relative mx-auto max-w-3xl">
    {#if $packageData.package.loading}
        <div class="mx-auto text-4xl">Loading... <Spinner /></div>
    {:else if $packageData.package.error}
        <div class="text-2xl text-red-600">There was an error loading the data. Please try again later.</div>
    {:else}
        <div class="container relative mx-auto">
            <div class="iframe-responsive-wrapper my-20">
                <iframe title="Freed By Knowledge Introduction Video" class="iframe-responsive" src={'https://player.vimeo.com/video/' + $packageData.package.data.Video} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <Buttons data={$packageData.package.data}/>
        </div>
        <div>
            <h2 class="text-3xl font-bold text-center">{$packageData.package.data.Name}</h2>
            <div class="mx-auto max-w-6xl leading-10 preview">{@html Marked($packageData.package.data.Text ?? '')}</div>
        </div>
    {/if}
</div>