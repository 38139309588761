<style lang="postcss">



    input[type='checkbox']:checked ~ .thumb {
        --transform-translate-y: 0;
        --transform-rotate: 0;
        --transform-skew-x: 0;
        --transform-skew-y: 0;
        --transform-scale-x: 1;
        --transform-scale-y: 1;
        transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
        --transform-translate-x: 100%;
        border-color: #48bb78;
    }

    input[type='checkbox']:checked ~ .track {
        --transform-translate-x: 0;
        --transform-translate-y: 0;
        --transform-rotate: 0;
        --transform-skew-x: 0;
        --transform-skew-y: 0;
        --transform-scale-x: 1;
        --transform-scale-y: 1;
        transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
        transition-property: background-color, border-color, color, fill, stroke;
        background-color: #48bb78;
    }

    input[type='checkbox']:disabled ~ .track {
        background-color: #a0aec0;
    }

    input[type='checkbox']:disabled ~ .thumb {
        background-color: #f7fafc;
        border-color: #a0aec0;
    }

    input[type='checkbox']:focus + .track,
    input[type='checkbox']:active + .track {
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
    }
</style>

<script>
    export let id = '';
    export let text = '';
    export let checked = false;
    export let disabled = false;
</script>

<label for="{id}">
    <div class="relative inline-block align-middle cursor-pointer select-none bg-transparent">
        <input {id} name="{id}" type="checkbox" class="sr-only" {disabled} bind:checked />
        <div class="track w-12 h-6 bg-gray-600 rounded-full shadow-inner"></div>
        <div class="thumb transition-all duration-300 ease-in-out absolute top-0 left-0 w-6 h-6 bg-white border-2 border-gray-600 rounded-full"></div>
    </div>
    <span class="ml-2 cursor-pointer">{text}</span>
</label>