let ws = false;

let waitingResponse = [];

const waitForOpenConnection = () => {
    console.log('Waiting for connection');
    return new Promise((resolve, reject) => {
        const maxNumberOfAttempts = 20
        const intervalTime = 100 //ms

        let currentAttempt = 0
        if (ws === false || ws.readyState !== ws.OPEN) {
            console.log('Connecting to websocket');
            connect();
        }
        const interval = setInterval(() => {
            console.log('Testing for connection');
            if (currentAttempt > maxNumberOfAttempts - 1) {
                console.log('Attempts exhausted');
                clearInterval(interval)
                reject(new Error('Maximum number of attempts exceeded'))
            } else if (ws.readyState === ws.OPEN) {
                console.log('Connected');
                clearInterval(interval)
                resolve()
            }
            currentAttempt++
        }, intervalTime)
    })
}

function connect() {
        ws = new WebSocket(((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/ws");
        ws.onmessage = handleMessage;
        ws.onclose = handleClose;
        ws.onerror = handleError;
}

function handleClose(e) {
    if (e.wasClean) {
        console.log('websocket closed cleanly');
    } else {
        console.log('websocket terminated');
    }
    ws = false;
}

function handleError(e) {
    console.log('ws error: ', e.message);
}

function handleMessage(e) {
    console.log('got message: ', e);
    let data = JSON.parse(e.data);
    console.log('data: ', data);
    if (data.Id !== undefined) {
        if (waitingResponse[data.Id] !== undefined) {
            if (data.success && data.success === true) {
                console.log('resolving messagePromise');
                waitingResponse[data.Id].resolve(data);
            } else {
                console.log('rejecting messagePromise');
                waitingResponse[data.Id].reject(new Error(data.Message));
            }
        }
    } else {
        console.log('unsolicited socket message received');
    }
}

export const messagePromise = async (msg) => {
    await waitForOpenConnection();

    let id = Math.random().toString(16).substr(2, 8);
    msg.id = id;
    return new Promise((resolve, reject) => {
        console.log('sending data: ', msg);
        ws.send(JSON.stringify(msg));
        waitingResponse[id] = {
            resolve: (data) => {
                resolve(data);
            }, reject: (error) => {
                reject(error)
            }};
    });
}