<script>
    import {quintIn} from 'svelte/easing';
    import {afterUpdate} from 'svelte';

    export let show = false;
    export let zIndex = 50;
    export let duration = 350;
    export let background = 'rgba(0,0,0,0.5)';
    let modalElement;

    function fade(node, { transitionDuration }) {
        return {
            duration: transitionDuration,
            tick: time => {
                node.style.opacity = String(quintIn(time));
            }
        };
    }

    let event = new CustomEvent("close", {
            detail: {
                show,
            },
            composed: true
        }
    );

    // closing modal programmatically, i.e. close button
    function modalClose() {
        show = false;
    }

    // clicking outside the modal closes it
    function modalOnClose(e) {
        if (e.target !== modalElement) {
            return;
        }

        // show will always be false, though if we needed to send dynamic data this would update the value.
        event.detail.show = show;

        // dispatch close event.
        e.target.dispatchEvent(event);

        show = false;
    }

    afterUpdate(() => {
        if (show) {
            document.body.classList.add('overflow-hidden', 'h-screen');
        }
        else {
            document.body.classList.remove('overflow-hidden', 'h-screen');
        }
    })

</script>

{#if show}
    <div style="background-color: {background}; z-index: {zIndex};"
         transition:fade="{{ transitionDuration: duration }}"
         bind:this={modalElement}
         class="w-full h-full mx-auto fixed left-0 top-0 modal overflow-scroll"
         on:click|stopPropagation={modalOnClose}>
        <div class="mx-auto my-8 relative bg-white rounded-3xl p-12 max-w-4xl">
            <button class="absolute right-6 top-6 rounded-3xl w-8 h-8 hover:text-red-500 bg-white z-50" on:click={modalClose}><i class="icon-cross"></i></button>
            <slot>Modal</slot>
        </div>
    </div>
{/if}