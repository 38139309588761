<script>
    import {category, getOrderedCategoriesWithPackages} from "../stores/category";
    import {packageData, getLatestPackage} from "../stores/package";
    import {onMount} from 'svelte';
    import Spinner from "../components/Spinner.svelte";

    let banner = require('/resources/img/packet-banner.jpg')

    onMount(() => {
        getLatestPackage()
        getOrderedCategoriesWithPackages()
    })

    function toggleCategory(cat) {
        let arrow = document.getElementById('arrow-' + cat);
        let container = document.getElementById('cat-' + cat);
        container.classList.toggle('max-h-a-lot');
        arrow.classList.toggle('category-arrow');
    }
</script>

<div>
    <div class="container mx-auto">
        {#if $category.categories.loading}
            <div class="text-3xl w-full text-center">
                Loading... <Spinner />
            </div>
        {:else if $category.categories.error}
            <div class="text-2xl w-full text-center text-red-600">
                There was an error loading the categories: {$category.categories.error}
            </div>
        {:else if !$category.categories.data || $category.categories.data.length === 0}
            <div class="p-12 font-bold text-2xl">There seems to be no categories available. Sorry!</div>
        {:else}
            {#if $packageData.package.loading}
                <div class="text-3xl w-full text-center">
                    Loading... <Spinner />
                </div>
            {:else if $packageData.package.error}
                <div class="text-2xl w-full text-center text-red-600">
                    There was an error loading the package: {$packageData.package.error}
                </div>
            {:else if !$packageData.package.data || $packageData.package.data.length === 0}
                <div class="p-12 font-bold text-2xl">There seems to be no package available. Sorry!</div>
            {:else}
                <div class="text-center text-3xl mt-12 mb-6">Latest Video</div>
                <div>
                    <a class="text-center outline-none focus:outline-none" href="/packets/{$packageData.package.data.Video}"><img class="max-h-48 max-w-96 mx-auto" src="{$packageData.package.data.Thumb}" alt="{$packageData.package.data.Name}" /></a>
                </div>
                <div class="text-center">{$packageData.package.data.Name}</div>
                <div class="container border-gray-500 border-b-2 mt-5"></div>
            {/if}
            {#each $category.categories.data as category}
                {#if category.Packages && category.Packages.length > 0}
                    <div>
                        <div class="text-center text-2xl my-6">{category.Name}</div>
                        {#each category.Packages as pkg}
                            <div class="inline-block m-8 w-48 align-top">
                                <div>
                                    <a class="outline-none focus:outline-none" href="/packets/{pkg.Video}"><img class="max-h-28 max-w-48 mx-auto" src="{pkg.Thumb}" alt="{pkg.Name}" /></a>
                                </div>
                                <div>{pkg.Name}</div>
                            </div>
                        {/each}
                        <div class="mt-3"></div>
                    </div>
                {/if}
            {/each}
        {/if}
    </div>
</div>