import {readable, get} from "svelte/store";

let setStore;

export const packageData = readable(null, set => {
    setStore = set;
    set({
        package: {
            loading: false,
            error: null,
            data: {},
        },
        packages: {
            loading: false,
            error: null,
            data: [],
        },
    });
    return () => {
    }
})

function setPackagesData(data) {
    let current = get(packageData);
    current.packages.data = data;
    setStore(current);
}

function setPackageData(data) {
    let current = get(packageData);
    current.package.data = data;
    console.log(current);
    setStore(current);
}

function setPackagesLoading(loading) {
    let current = get(packageData);
    current.packages.loading = loading;
    setStore(current);
}

function setPackagesError(error) {
    let current = get(packageData);
    current.packages.error = error;
    setStore(current);
}

function setPackageError(error) {
    let current = get(packageData);
    current.package.error = error;
    setStore(current);
}

function setPackageLoading(loading) {
    let current = get(packageData);
    current.package.loading = loading;
    setStore(current);
}

export function getPackages(count = null, start = null) {
    console.log('getting packages');
    let params = new URLSearchParams();
    if (count) {
        params.set('count', count);
    }
    if (start) {
        params.set('start', start);
    }
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setPackagesLoading(true);
    setPackagesError(null);
    console.log('fetching packages');
    fetch('/api/v1/packages' + params.toString(), {
        method: 'GET',
        headers: headers,
    })
        .then((msg) => {
            console.log('fetched:', msg);
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        // message means something went wrong
                        setPackagesLoading(false);
                        setPackagesError(json.message);
                        setPackagesData([]);
                    } else {
                        setPackagesLoading(false);
                        setPackagesError(null);
                        setPackagesData(json);
                    }
                })
            } else {
                // not success
                setPackagesLoading(false);
                setPackagesError(msg.statusText);
                setPackagesData([]);
            }
        })
        .catch((error) => {
            console.log('fetching failed: ', error);
            setPackagesLoading(false);
            setPackagesError(error.message);
            setPackagesData([]);
        })
}

export function getPackage(id) {
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setPackageLoading(true);
    setPackageError(null);
    console.log('fetching package ' + id);
    fetch('/api/v1/packages/'+id, {
        method: 'GET',
        headers: headers,
    })
        .then((msg) => {
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        // message means something went wrong
                        setPackageLoading(false);
                        setPackageError(json.message);
                        setPackageData({});
                    } else {
                        console.log('got package', json);
                        setPackageLoading(false);
                        setPackageError(null);
                        setPackageData(json);
                    }
                })
            } else {
                // not success
                setPackageLoading(false);
                setPackageError(msg.statusText);
                setPackageData({});
            }
        })
        .catch((error) => {
            setPackageLoading(false);
            setPackageError(error.message);
            setPackageData({});
        })
}

export function getLatestPackage() {
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setPackageLoading(true);
    setPackageError(null);
    console.log('fetching latest package');
    fetch('/api/v1/packages/latest', {
        method: 'GET',
        headers: headers,
    })
        .then((msg) => {
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        // message means something went wrong
                        setPackageLoading(false);
                        setPackageError(json.message);
                        setPackageData({});
                    } else {
                        console.log('got package', json);
                        setPackageLoading(false);
                        setPackageError(null);
                        setPackageData(json);
                    }
                })
            } else {
                // not success
                setPackageLoading(false);
                setPackageError(msg.statusText);
                setPackageData({});
            }
        })
        .catch((error) => {
            setPackageLoading(false);
            setPackageError(error.message);
            setPackageData({});
        })
}

// technically not part of the store, but to keep all the package API stuff in one place i am putting it here
// pkg must be package ID
export function deletePackage(pkg) {
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setPackageLoading(true);
    setPackageError(null);
    console.log('fetching latest package');
    return fetch('/api/v1/packages/' + pkg, {
        method: 'DELETE',
        headers: headers,
    })
        .then((msg) => {
            return msg.ok;
        })
        .catch((error) => {
            console.log(error)
            return false;
        })
}