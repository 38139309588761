<svelte:head>
    <title>Freed By Knowledge</title>
</svelte:head>

<script>
    import { onMount } from "svelte";
    import Modal from '../components/Modal.svelte';
    import PackageDisplay from '../components/package/Display.svelte';
    import {packageData, getPackage} from '../stores/package';

    let treePlayButton = require('/resources/img/Tree Play Button.png');
    let treeCycle = require('/resources/img/4rs.png');
    let reveal = require('/resources/img/reveal.jpeg');
    let receive = require('/resources/img/receive.jpeg');
    let reap = require('/resources/img/reap.jpeg');
    let repeat = require('/resources/img/repeat.jpeg');
    let showModal = false;
    $packageData;

    // on navigating to this page, scroll to the top
    onMount(() => window.scrollTo(0,0));
</script>


<Modal bind:show="{showModal}">
    <PackageDisplay />
</Modal>
<div>
    <div class="h-full flex items-center tree-bg justify-center min-h-screen p-2">
        <div class="flex items-center justify-center container flex-wrap">
            <div class="flex flex-col text-3xl md:text-5xl max-w-2xl font-bold xl:mr-48 pb-4">
                <h1 class="pb-3 md:pb-12 leading-loose">Freed By Knowledge</h1>
                <p class="text-2xl md:text-3xl">A place dedicated to highlighting laws and truths that, when observed, increase knowledge and liberate an individual to new heights.</p>
            </div>
            <button class="flex flex-col outline-none focus:outline-none pb-12" on:click={()=>{getPackage('505766016'); showModal = true}}><img class="max-w-xs" src="{treePlayButton}" alt="Play Intro Video"/></button>
        </div>
        <div class="mx-auto absolute bottom-4 arrow-down hidden md:block"></div>
    </div>
    <div class="mt-8 md:mt-10 container mx-auto p-2">
        <h2 class="text-3xl mb-8 md:mb-12">Principles of Progression</h2>
        <div class="flex justify-center flex-wrap">
            <div class="flex-col iframe-responsive-wrapper md:mr-12 max-w-2xl">
                <iframe title="Freed By Knowledge Introduction Video" class="iframe-responsive" src="https://player.vimeo.com/video/538822472" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="flex-col text-2xl max-w-2xl mt-4">
                <p class="block">Critical elements accompany our progression in life just as we observe a kite being successfully flown. Among these are: Truth, Laws and Principles, Commitment, and Opposition.</p>
            </div>
        </div>
        <div class="w-full border-b-4 border-gray-500 my-8"></div>
        <div class="flex flex-wrap gap-6 lg:gap-36">
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Laws</h3>
                <p class="mt-8">
                    The string tethers the kite to the person just as laws tether us to the Truth. There is soundness and security in laws that are rooted in truth.
                </p>
            </div>
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Truth</h3>
                <p class="mt-8">
                    Just as the kite is reliant on the person flying it to guide it through the necessary opposing forces, when we bind ourselves to the Truth we also are lifted and guided.
                </p>
            </div>
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Commitment</h3>
                <p class="mt-8">
                    Represented by the knot, commitment to laws rooted in truth puts us on the path to being freed by knowledge.
                </p>
            </div>
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Opposition</h3>
                <p class="mt-8">
                    The kite benefits from the opposing wind, just as we benefit from opposition in every aspect of life when we choose to see it as a great source of our progression.
                </p>
            </div>
        </div>
        <h2 class="text-3xl mt-8 md:mt-20">The Divine Process</h2>
        <img class="mx-auto md:max-w-2xl max-w-full mt-8" src="{treeCycle}" alt="Reveal - Receive - Reap - Repeat" />
        <div class="w-full border-b-4 border-gray-500 my-8"></div>
        <div class="flex flex-wrap gap-6 lg:gap-36">
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Reveal</h3>
                <button class="outline-none focus:outline-none" on:click={()=>{getPackage('538860795'); showModal = true}}>
                    <img src="{reveal}" alt="Reveal"/>
                </button>
                <p class="mt-8">
                    An idea revealed to us through some other source; thought, spoken, read, dreamed, observed, felt are a few of the mediums through which things are revealed.
                </p>
            </div>
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Receive</h3>
                <button class="outline-none focus:outline-none" on:click={()=>{getPackage('538843357'); showModal = true}}>
                    <img src="{receive}" alt="Receive"/>
                </button>
                <p class="mt-8">
                    The reveal stage takes curiosity and seeking. The receive stage takes change. This is where we take into us, and commit to following and observing, that which was revealed.
                </p>
            </div>
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Reap</h3>
                <button class="outline-none focus:outline-none" on:click={()=>{getPackage('538835884'); showModal = true}}>
                    <img src="{reap}" alt="Reap"/>
                </button>
                <p class="mt-8">
                    A harvest frees us from poverty spiritually, emotionally, or temporally. When freed we are enabled to feed and liberate others.
                </p>
            </div>
            <div class="flex flex-initial md:flex-1 flex-col mt-6 md:mt-0">
                <h3 class="text-center text-2xl">Repeat</h3>
                <button class="outline-none focus:outline-none" on:click={()=>{getPackage('538852366'); showModal = true}}>
                    <img src="{repeat}" alt="Repeat"/>
                </button>
                <p class="mt-8">
                    During repeat, we can reflect, review, express gratitude, report back and plan with the next objective.
                </p>
            </div>
        </div>
    </div>
</div>