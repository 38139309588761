<script>
    import {onMount} from 'svelte';
    import * as FilePond from 'filepond';
    import marked from 'marked';
    import {category, getCategories} from "../../stores/category";
    import Switch from "../Switch.svelte";

    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
    import "filepond/dist/filepond.min.css";

    // filepond plugins
    import FPPluginImagePreview from 'filepond-plugin-image-preview';

    let saving = false;
    let error = false;
    export let data = {
        ID: '',
        Video: '',
        Thumb: '',
        Name: '',
        Download: '',
        Audio: '',
        Text: '',
        Published: false,
        CategoryID: '',
        Position: '',
    };

    const TEXT_EDIT = 1;
    const TEXT_PREVIEW = 2;
    const TEXT_HELP = 3;

    // flip between markdown edit, preview, and help
    let textTab = TEXT_EDIT;

    let textEditTab, textPreviewTab, textHelpTab;
    let preview;
    let textInput;

    let textDiv, previewDiv, helpDiv;

    let thumbPond, audioPond, pdfPond, addPond;

    FilePond.registerPlugin(FPPluginImagePreview);

    function handleVideoBlur() {
        return () => {
            console.log('attempting to get video thumbnail')
            if (data.Video) {
                getThumb(data.Video);
            }
        }
    }

    function handleSave() {
        return () => {
            saving = true;
            console.log('saving package');
            let formData = new FormData(document.getElementById('package-edit'));
            data.Thumb = formData.get('thumb');
            data.Audio = formData.get('audio');
            data.Download = formData.get('download');
            data.Published = data.Published === true ? 1 : 0;
            if (typeof data.Thumb === 'object' || data.Thumb === null) {
                data.Thumb = '';
            }
            if (typeof data.Audio === 'object' || data.Audio === null) {
                data.Audio = '';
            }
            if (typeof data.Download === 'object' || data.Download === null) {
                data.Download = '';
            }
            // so that it pulls from categoryID
            delete data.Category;
            let body = JSON.stringify(data);
            let headers = new Headers({
                "Content-Type": "application/json",
                "Content-Length": body.length.toString()
            })

            let method = 'POST';
            let url = '/api/v1/packages';
            if (data.ID) {
                method = 'PUT'
                url += '/' + data.ID;
            }

            fetch(url, {
                method: method,
                headers: headers,
                body: body
            })
                .then((msg) => {
                    if (msg.ok) {
                        console.log('successfully saved')
                        if (msg.headers.get('location')) {
                            saving = false;
                            window.location = msg.headers.get('location');
                        }
                    } else {
                        // not success
                        console.log(msg.statusText);
                        error = true;
                        saving = false;
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    error = true;
                    saving = false;
                })
        }
    }

    function getOptions(checkForFile) {
        let options =  {
            server: '/api/v1/file',
        };
        if (checkForFile) {
            options.files = [
                {
                    // the server file reference (url to file)
                    source: checkForFile,

                    // set type to local to indicate an already uploaded file
                    options: {
                        type: 'local',
                    }
                }
            ]
        }
        return options;
    }

    onMount(() => {
        // make sure we have categories loaded
        if (!$category.categories.data || $category.categories.data.length === 0) {
            getCategories();
        }

        const thumbFile = document.querySelector('#thumb');
        thumbPond = FilePond.create(thumbFile, getOptions(data.Thumb));
        const audioFile = document.querySelector('#audio');
        audioPond = FilePond.create(audioFile, getOptions(data.Audio));
        const pdfFile = document.querySelector('#download');
        pdfPond = FilePond.create(pdfFile, getOptions(data.Download));
        const addFile = document.querySelector('#add-image');
        addPond = FilePond.create(addFile, {
            server: '/api/v1/file',
            labelIdle: 'Drag & Drop your image or <span class="filepond--label-action"> Browse </span> to insert into text',
            onprocessfile: (err, file) => {
                if (!err) {
                    let img = file.serverId;
                    if (img.startsWith('.')) {
                        img = img.slice(1);
                    }
                    img = '![Image Title]('+img+')';
                    insertAtCursor(img);
                }
            },
            credits: null,
        });

        textEditTab = document.getElementById('textEditTab');
        textPreviewTab = document.getElementById('textPreviewTab');
        textHelpTab = document.getElementById('textHelpTab');
        textInput = document.getElementById('text');
        textDiv = document.getElementById('textDiv');
        previewDiv = document.getElementById('previewDiv');
        helpDiv = document.getElementById('helpDiv');
    })

    function insertAtCursor(text) {
       if (textInput.selectionStart || textInput.selectionStart === '0') {
            var startPos = textInput.selectionStart;
            var endPos = textInput.selectionEnd;
           textInput.value = textInput.value.substring(0, startPos)
                + text
                + textInput.value.substring(endPos, textInput.value.length);
        } else {
           textInput.value += text;
        }
       // force text property to update
        data.Text = textInput.value;
    }

    // https://gist.github.com/bacoords/77ee4d13dfa76db03981cb4eb0df0c6f - get thumbnail of vimeo video
    function getThumb(videoId) {
        let url = 'https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/' + videoId;
        console.log('fetching video data from ' + url);
        fetch(url, {}).then(res => {
            console.log(res);
            res.json().then(json => {
                console.log('setting thumb to ' + json.thumbnail_url);
                thumbPond.addFile(json.thumbnail_url);
            })
            .catch(() => {})
        })
        .catch(() => {})
    }

    function switchTab(event) {
        switch (event.target.id) {
            case 'textEditTab':
                textEditTab.classList.remove('text-gray-500', 'bg-gray-200');
                textPreviewTab.classList.add('text-gray-500', 'bg-gray-200');
                textHelpTab.classList.add('text-gray-500', 'bg-gray-200');
                textTab = TEXT_EDIT;
                previewDiv.style.display = 'none';
                helpDiv.style.display = 'none';
                textDiv.style.display = 'block';
                break;
            case 'textPreviewTab':
                textEditTab.classList.add('text-gray-500', 'bg-gray-200');
                textPreviewTab.classList.remove('text-gray-500', 'bg-gray-200');
                textHelpTab.classList.add('text-gray-500', 'bg-gray-200');
                textTab = TEXT_PREVIEW;
                if (data.Text) {
                    preview = marked(data.Text);
                } else {
                    preview = '';
                }
                helpDiv.style.display = 'none';
                textDiv.style.display = 'none';
                previewDiv.style.display = 'block';
                break;
            case 'textHelpTab':
                textEditTab.classList.add('text-gray-500', 'bg-gray-200');
                textPreviewTab.classList.add('text-gray-500', 'bg-gray-200');
                textHelpTab.classList.remove('text-gray-500', 'bg-gray-200');
                textTab = TEXT_HELP;
                previewDiv.style.display = 'none';
                textDiv.style.display = 'none';
                helpDiv.style.display = 'block';
                break;
        }
    }
</script>

<div class="container mx-auto">
    <div class="lg:mx-4">
        <div class="mt-5 md:mt-0">
            <form action="#" method="POST" id="package-edit">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div class="grid grid-cols-3 gap-6">
                            <div class="col-span-3 sm:col-span-2">
                                <label for="name" class="block text-sm font-medium text-gray-700">
                                    Title
                                </label>
                                <div class="mt-1 flex rounded-md shadow-sm">
                                    <input type="text" name="name" id="name"
                                           bind:value={data.Name}
                                           class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                           placeholder="Package Title">
                                </div>
                            </div>
                        </div>

                        <div>
                            <label for="video" class="block text-sm font-medium text-gray-700">
                                Video ID
                            </label>
                            <div class="mt-1">
                                <input type="text" name="video" id="video"
                                       bind:value={data.Video}
                                       on:blur={handleVideoBlur()}
                                       class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                       placeholder="123456789">
                            </div>
                            <p class="mt-2 text-sm text-gray-500">
                                 The ID of the video on Vimeo, from the URL. https://vimeo.com/XXXXXXXXX where XXXXXXXXX is the video ID.
                            </p>
                        </div>

                        <div>
                            <div class="block text-sm font-medium text-gray-700">
                                Thumbnail
                            </div>
                            <div class="mt-1">
                                <input type="file" id="thumb" name="thumb">
                            </div>
                            <p class="mt-2 text-sm text-gray-500 block">
                                Thumbnail for the video. This will be automatically retrieved from the video ID you put in above, but you can change it here as well.
                            </p>
                        </div>

                        <div>
                            <div class="block text-sm font-medium text-gray-700">
                                Audio
                            </div>
                            <div class="mt-1">
                                <input type="file" id="audio" name="audio">
                            </div>
                            <p class="mt-2 text-sm text-gray-500 block">
                                Audio for listening. MP3 format.
                            </p>
                        </div>
                        <div>
                            <div class="block text-sm font-medium text-gray-700">
                                PDF Download
                            </div>
                            <div class="mt-1">
                                <input type="file" id="download" name="download">
                            </div>
                            <p class="mt-2 text-sm text-gray-500 block">
                                PDF of package for downloading.
                            </p>
                        </div>
                        <div>
                            <label for="category" class="block text-sm font-medium text-gray-700">
                                Category
                            </label>
                            <div class="mt-1">
                                <select id="category" name="category" bind:value={data.CategoryID}>
                                    <option value="-1" disabled>Select a category...</option>
                                    {#each $category.categories.data as cat}
                                        <option value={cat.ID} selected={data.CategoryID === cat.ID}>{cat.Name}</option>
                                    {/each}
                                </select>
                            </div>
                            <p class="mt-2 text-sm text-gray-500 block">
                                Category the video will show in.
                            </p>
                        </div>
                        <div class="col-span-3 sm:col-span-2">
                            <label for="position" class="block text-sm font-medium text-gray-700">
                                Position
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                                <input type="number" name="position" id="position"
                                       bind:value={data.Position}
                                       class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                       placeholder="50">
                            </div>
                            <p class="mt-2 text-sm text-gray-500 block">
                                Position of the video inside the category. Lower numbers will be displayed before higher numbers.
                            </p>
                        </div>
                        <div class="col-span-3 sm:col-span-2">
                            <Switch bind:checked="{data.Published}" id="published" text="Published" />
                            <p class="mt-2 text-sm text-gray-500 block">
                                Is the package published. Unpublished packages will not show on the list, but can still be accessed directly.
                            </p>
                        </div>

                        <div>
                            <div class="block text-sm font-medium text-gray-700">
                                Text
                            </div>
                            <div class="mt-1">
                                <div class="border-b-2 border-gray-200 mb-5">
                                    <ul class='flex cursor-pointer'>
                                        <li id="textEditTab"
                                            class='py-2 px-6 bg-white rounded-t-lg -mb-0.5 border-r-2 border-t-2 border-l-2'
                                            on:click|preventDefault={switchTab}
                                        >Edit</li>
                                        <li id="textPreviewTab"
                                            class='py-2 px-6 bg-white rounded-t-lg -mb-0.5 border-r-2 border-t-2 border-l-2 text-gray-500 bg-gray-200'
                                            on:click|preventDefault={switchTab}
                                        >Preview</li>
                                        <li id="textHelpTab"
                                            class='py-2 px-6 bg-white rounded-t-lg -mb-0.5 border-r-2 border-t-2 border-l-2 text-gray-500 bg-gray-200'
                                            on:click|preventDefault={switchTab}
                                        >Help</li>
                                    </ul>
                                </div>
                                <div id="textDiv">
                                    <input type="file" id="add-image" name="add-image">
                                    <textarea id="text" name="text" bind:value={data.Text} class="w-full h-72"></textarea>
                                </div>
                                <div id="previewDiv" class="preview w-full h-80 overflow-auto" style="display:none;">
                                    {@html preview}
                                </div>
                                <div id="helpDiv" class="w-full h-80 overflow-auto" style="display:none;">
                                    <textarea id="help" name="help" class="w-full h-80">
Markdown Quick Reference
========================

This guide is a very brief overview, with examples, of the syntax that [Markdown] supports. It is itself written in Markdown and you can copy the samples over to the left-hand pane for experimentation. It's shown as *text* and not *rendered HTML*.

[Markdown]: http://daringfireball.net/projects/markdown/


Simple Text Formatting
======================

First thing is first. You can use *stars* or _underscores_ for italics. **Double stars** and __double underscores__ for bold. ***Three together*** for ___both___.

Paragraphs are pretty easy too. Just have a blank line between chunks of text.

> This chunk of text is in a block quote. Its multiple lines will all be
> indented a bit from the rest of the text.
>
> > Multiple levels of block quotes also work.

Sometimes you want to include code, such as when you are explaining how `<h1>` HTML tags work, or maybe you are a programmer and you are discussing `someMethod()`.

If you want to include code and have new
lines preserved, indent the line with a tab
or at least four spaces:

Extra spaces work here too.
This is also called preformatted text and it is useful for showing examples.
The text will stay as text, so any *markdown* or <u>HTML</u> you add will
not show up formatted. This way you can show markdown examples in a
markdown document.

>     You can also use preformatted text with your blockquotes
>     as long as you add at least five spaces.


Headings
========

There are a couple of ways to make headings. Using three or more equals signs on a line under a heading makes it into an "h1" style. Three or more hyphens under a line makes it "h2" (slightly smaller). You can also use multiple pound symbols (`#`) before and after a heading. Pounds after the title are ignored. Here are some examples:

This is H1
==========

This is H2
----------

# This is H1
## This is H2
### This is H3 with some extra pounds ###
#### You get the idea ####
##### I don't need extra pounds at the end
###### H6 is the max


Links
=====

Let's link to a few sites. First, let's use the bare URL, like <https://www.github.com>. Great for text, but ugly for HTML.
Next is an inline link to [Google](https://www.google.com). A little nicer.
This is a reference-style link to [Wikipedia] [1].
Lastly, here's a pretty link to [Yahoo]. The reference-style and pretty links both automatically use the links defined below, but they could be defined *anywhere* in the markdown and are removed from the HTML. The names are also case insensitive, so you can use [YaHoO] and have it link properly.

[1]: https://www.wikipedia.org
[Yahoo]: https://www.yahoo.com

Title attributes may be added to links by adding text after a link.
This is the [inline link](https://www.bing.com "Bing") with a "Bing" title.
You can also go to [W3C] [2] and maybe visit a [friend].

[2]: https://w3c.org (The W3C puts out specs for web-based things)
[Friend]: https://facebook.com "Facebook!"

Email addresses in plain text are not linked: test@example.com.
Email addresses wrapped in angle brackets are linked: <test@example.com>.
They are also obfuscated so that email harvesting spam robots hopefully won't get them.


Lists
=====

* This is a bulleted list
* Great for shopping lists
- You can also use hyphens
+ Or plus symbols

The above is an "unordered" list. Now, on for a bit of order.

1. Numbered lists are also easy
2. Just start with a number
3738762. However, the actual number doesn't matter when converted to HTML.
1. This will still show up as 4.

You might want a few advanced lists:

- This top-level list is wrapped in paragraph tags
- This generates an extra space between each top-level item.

- You do it by adding a blank line

- This nested list also has blank lines between the list items.

- How to create nested lists
1. Start your regular list
2. Indent nested lists with two spaces
3. Further nesting means you should indent with two more spaces
* This line is indented with four spaces.

- List items can be quite lengthy. You can keep typing and either continue
them on the next line with no indentation.

- Alternately, if that looks ugly, you can also
indent the next line a bit for a prettier look.

- You can put large blocks of text in your list by just indenting with two spaces.

This is formatted the same as code, but you can inspect the HTML
and find that it's just wrapped in a `<p>` tag and *won't* be shown
as preformatted text.

You can keep adding more and more paragraphs to a single
list item by adding the traditional blank line and then keep
on indenting the paragraphs with two spaces.

You really only need to indent the first line,
but that looks ugly.

- Lists support blockquotes

> Just like this example here. By the way, you can
> nest lists inside blockquotes!
> - Fantastic!

- Lists support preformatted text

  You just need to indent an additional four spaces.


Even More
=========

Horizontal Rule
---------------

If you need a horizontal rule you just need to put at least three hyphens, asterisks, or underscores on a line by themselves. You can also even put spaces between the characters.

---
****************************
_ _ _ _ _ _ _

Those three all produced horizontal lines. Keep in mind that three hyphens under any text turns that text into a heading, so add a blank like if you use hyphens.

Images
------

Images work exactly like links, but they have exclamation points in front. They work with references and titles too.

![Google Logo](https://www.google.com/images/errors/logo_sm.gif) and ![Happy].

[Happy]: https://wpclipart.com/smiley/happy/simple_colors/smiley_face_simple_green_small.png ("Smiley face")


Inline HTML
-----------

If markdown is too limiting, you can just insert your own <strike>crazy</strike> HTML. Span-level HTML <u>can *still* use markdown</u>. Block level elements must be separated from text by a blank line and must not have any spaces before the opening and closing HTML.

<div style='font-family: "Comic Sans MS", "Comic Sans", cursive;'>
It is a pity, but markdown does **not** work in here for most markdown parsers.
[Marked] handles it pretty well.
</div>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        {#if error}
                            <p class="text-red-500 mt-4 text-2xl text-center">Error saving data, please check your inputs!</p>
                        {/if}
                        <button type="submit"
                                disabled={saving}
                                on:click|preventDefault={handleSave()}
                                class="button-green inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none hover:bg-gray-200">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<style global>

</style>