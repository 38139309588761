<script>
    import Spinner from './Spinner.svelte';

    let data = {
        email: '',
        message: '',
        city2: '',
    }
    let error = false;
    let saving = false;

    function handleSave() {
        return () => {
            saving = true;
            let body = JSON.stringify(data);
            let headers = new Headers({
                "Content-Type": "application/json",
                "Content-Length": body.length.toString()
            })

            let method = 'POST';
            let url = '/api/v1/email';

            fetch(url, {
                method: method,
                headers: headers,
                body: body
            })
                .then((msg) => {
                    if (msg.ok) {
                        console.log('successfully sent')
                        saving = false;
                        window.location = "/thanks";
                    } else {
                        // not success
                        console.log(msg.statusText);
                        error = true;
                        saving = false;
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    error = true;
                    saving = false;
                })
        }
    }
</script>

<div class="container mx-auto">
    <form action="#" method="POST" id="package-edit">
        <div class="p-8">
            Send a message to Freed By Knowledge
        </div>
        <div class="grid grid-cols-3 gap-6">
            <div class="col-span-3">
                <label for="name" class="block text-sm font-medium text-gray-700">
                    Email
                </label>
                <input type="text" name="name" id="name"
                       bind:value={data.email}
                       class="mt-1 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full sm:text-sm border-gray-300"
                       placeholder="yourname@example.com">
            </div>
        </div>
        <div class="grid grid-cols-3 gap-6 mt-8">
            <div class="col-span-3">
                <label for="message" class="block text-sm font-medium text-gray-700">Message</label>
                <textarea id="message" name="message" bind:value={data.message} class="mt-1 rounded-md shadow-sm w-full h-32"></textarea>
            </div>
        </div>
        <input type="hidden" bind:value={data.city2} />
        <div class="px-4 py-3 text-right sm:px-6">
            {#if error}
                <p class="text-red-500 mt-4 text-2xl text-center">Error submitting data, please check your inputs!</p>
            {/if}
            {#if saving}
                <div class="text-gray-500 text-2xl"><Spinner /></div>
            {/if}
            <button type="submit"
                    disabled={saving}
                    on:click|preventDefault={handleSave()}
                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
                Send Message
            </button>
        </div>
    </form>
</div>