<script>
    import Login from './Login.svelte';
    import Modal from './Modal.svelte';
    import Contact from './Contact.svelte'

    let showContact = false;
</script>

<div class="w-full flex py-5 mt-32">
    <div class="mx-auto">Copyright &copy; 2024 Freed By Knowledge | <a href="/about">About</a> | <button style="color: #449fdb; outline: none" on:click={() => showContact = true}>Contact</button> | <div class="inline-block relative"><Login/></div></div>
</div>
<Modal bind:show={showContact}>
    <Contact />
</Modal>