import {readable, get} from "svelte/store";

let setStore;

export const category = readable(null, set => {
    setStore = set;
    set({
        category: {
            loading: false,
            error: null,
            data: {},
        },
        categories: {
            loading: false,
            error: null,
            data: [],
        },
    });
    return () => {
    }
})

function setCategoriesData(data) {
    let current = get(category);
    current.categories.data = data;
    setStore(current);
}

function setCategoryData(data) {
    let current = get(category);
    current.category.data = data;
    setStore(current);
}

function setCategoriesLoading(loading) {
    let current = get(category);
    current.categories.loading = loading;
    setStore(current);
}

function setCategoriesError(error) {
    let current = get(category);
    current.categories.error = error;
    setStore(current);
}

function setCategoryError(error) {
    let current = get(category);
    current.category.error = error;
    setStore(current);
}

function setCategoryLoading(loading) {
    let current = get(category);
    current.category.loading = loading;
    setStore(current);
}

export function getCategories(count = null, start = null) {
    console.log('getting categories');
    let params = new URLSearchParams();
    if (count) {
        params.set('count', count);
    }
    if (start) {
        params.set('start', start);
    }
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setCategoriesLoading(true);
    setCategoriesError(null);
    console.log('fetching categories');
    fetch('/api/v1/categories' + params.toString(), {
        method: 'GET',
        headers: headers,
    })
        .then((msg) => {
            console.log('fetched:', msg);
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        // message means something went wrong
                        setCategoriesLoading(false);
                        setCategoriesError(json.message);
                        setCategoriesData([]);
                    } else {
                        setCategoriesLoading(false);
                        setCategoriesError(null);
                        setCategoriesData(json);
                    }
                })
            } else {
                // not success
                setCategoriesLoading(false);
                setCategoriesError(msg.statusText);
                setCategoriesData([]);
            }
        })
        .catch((error) => {
            console.log('fetching failed: ', error);
            setCategoriesLoading(false);
            setCategoriesError(error.message);
            setCategoriesData([]);
        })
}

export function getOrderedCategoriesWithPackages(count = null, start = null) {
    console.log('getting ordered categories with packages');
    let params = new URLSearchParams();
    if (count) {
        params.set('count', count);
    }
    if (start) {
        params.set('start', start);
    }
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setCategoriesLoading(true);
    setCategoriesError(null);
    console.log('fetching categories');
    fetch('/api/v1/categories/ordered' + params.toString(), {
        method: 'GET',
        headers: headers,
    })
        .then((msg) => {
            console.log('fetched:', msg);
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        // message means something went wrong
                        setCategoriesLoading(false);
                        setCategoriesError(json.message);
                        setCategoriesData([]);
                    } else {
                        setCategoriesLoading(false);
                        setCategoriesError(null);
                        setCategoriesData(json);
                    }
                })
            } else {
                // not success
                setCategoriesLoading(false);
                setCategoriesError(msg.statusText);
                setCategoriesData([]);
            }
        })
        .catch((error) => {
            console.log('fetching failed: ', error);
            setCategoriesLoading(false);
            setCategoriesError(error.message);
            setCategoriesData([]);
        })
}

export function getCategory(id) {
    let headers = new Headers({
        "Content-Type": "application/json",
    })

    setCategoryLoading(true);
    setCategoryError(null);
    fetch('/api/v1/categories/'+id, {
        method: 'GET',
        headers: headers,
    })
        .then((msg) => {
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        // message means something went wrong
                        setCategoryLoading(false);
                        setCategoryError(json.message);
                        setCategoryData({});
                    } else {
                        setCategoryLoading(false);
                        setCategoryError(null);
                        setCategoryData(json);
                    }
                })
            } else {
                // not success
                setCategoryLoading(false);
                setCategoryError(msg.statusText);
                setCategoryData({});
            }
        })
        .catch((error) => {
            setCategoryLoading(false);
            setCategoryError(error.message);
            setCategoryData({});
        })
}