import {messagePromise} from "./websocket";
import {readable, get} from "svelte/store";

let setStore;

export const user = readable(null, set => {
    setStore = set;
    if (userData.ID !== undefined) {
        set({
            authenticated: true,
            roles: userData.Roles,
            id: userData.ID,
            username: userData.Name,
            email: userData.Email,
            loading: false,
            error: false,
        });
    } else {
        set({
            authenticated: false,
            id: '',
            roles: [],
            username: 'Guest',
            email: '',
            loading: false,
            error: false,
        });
    }
    return () => {}
})

export function login(username, password) {
    let current = get(user);
    current.loading = true;
    current.error = false;
    setStore(current);
    console.log('sending login fetch post');
    let body = JSON.stringify({username, password});
    let headers = new Headers({
        "Content-Type": "application/json",
        "Content-Length": body.length
    })

    fetch('/api/v1/users/login', {
        method: 'POST',
        headers: headers,
        body: body
    })
        .then((msg) => {
            if (msg.ok) {
                msg.json().then(json => {
                    if (json.message !== undefined) {
                        setStore({
                            authenticated: false,
                            id: '',
                            roles: [],
                            username: 'Guest',
                            email: '',
                            loading: false,
                            error: json.message,
                        });
                    } else {
                        let roles = [];
                        json.Roles.forEach(role => {
                            roles.push(role.Name)
                        })
                        setStore({
                            authenticated: true,
                            id: json.ID,
                            roles: roles,
                            username: json.Name,
                            email: json.Email,
                            loading: false,
                            error: false,
                        });
                    }
                })
            } else {
                // not success
                setStore({
                    authenticated: false,
                    id: '',
                    roles: [],
                    username: 'Guest',
                    email: '',
                    loading: false,
                    error: msg.statusText,
                });
            }
        })
        .catch((error) => {
            setStore({
                authenticated: false,
                id: '',
                roles: [],
                username: 'Guest',
                email: '',
                loading: false,
                error: error.message,
            });
        })
}

export function logout() {
    let current = get(user);
    current.loading = true;
    current.error = false;
    setStore(current);

    fetch('/api/v1/users/logout', {
        method: 'POST',
        body: ''
    })
        .then((msg) => {
            if (msg.ok) {
                setStore({
                    authenticated: false,
                    roles: [],
                    username: 'Guest',
                    loading: false,
                    error: false,
                });
            } else {
                // not success, don't change store
                console.log(msg.statusText)
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export function isAdmin() {
    let current = get(user);
    return current.roles.indexOf('admin') !== -1;
}